import React from "react";
import { Link } from "gatsby";
const Modal = ({isOpen}) => {
    return (
        <>
            {isOpen ? (
                <div className={"w-full  align-middle justify-center  bg-tema4 text-white"}>
                    <div className={""}>
                        <ul className={"flex flex-col py-1"}>
                            <li className={"p-2 text-white"}>
                                About us
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2">
                                        <Link to={"/acerca/que-es"}>
                                            What is QuEST?
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/objetivos"}>
                                            Goals
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/estructura"}>
                                            Structure
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/financiamiento"}>
                                            Founding
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Quality in Health
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/calidadensalud/definiciones"}>
                                            Definitions
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/calidadensalud/importancia"}>
                                            Importance in LAC
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Projects
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/proyectos/enmarcha"}>
                                            On going
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/proyectos/futuros"}>
                                            Futures
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Activities
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/webinars"}>
                                            Webinars
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/cursos-cortos"}>
                                            Short Courses
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/publicaciones"}>
                                            Publications
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/convocatorias"}>
                                            Calls
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"p-2 border-2"}>
                            <Link to={"/contacto"}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Modal;